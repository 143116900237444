<template>
  <v-dialog v-model="dialogUser" persistent max-width="550px">
    <template v-slot:activator="{ on }">
      <!--<v-btn v-on="on" color="primary" dark>
        <v-icon left>mdi-account-plus</v-icon>
        Novo Usuário
      </v-btn>-->
    </template>
    <v-card class="v-card-profile">
      <v-app-bar color="primary" dark flat>
        <v-toolbar-title class="body-2">
          <v-avatar size="48" right class="mr-3" v-if="userEdit.photoURL">
            <v-img :src="userEdit.photoURL" :alt="userEdit.displayName" />
          </v-avatar>
          <!-- <v-spacer></v-spacer> -->
          Cadastro de usuário
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="dialogUser = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <!--v-model="valid" ref="form" lazy-validation -->
          <v-layout row wrap>
            <v-flex lg12 sm12>
              <v-text-field
                label="Nome Completo"
                v-model="userEdit.displayName"
              >
              </v-text-field>
            </v-flex>
            <v-flex lg12 sm12>
              <v-text-field
                label="E-mail"
                v-model="userEdit.email"
                :disabled="userEdit['.key'] !== undefined"
              >
              </v-text-field>
            </v-flex>
            <v-flex lg12 sm12>
              <v-text-field label="CPF/CNPJ" v-model="userEdit.cpfcnpj">
              </v-text-field>
            </v-flex>
            <!-- <v-flex lg12 sm12>
              <v-select
                v-model="userEdit.franquia"
                :items="franquiasList"
                item-text="titulo"
                label="Franquia"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-flex> -->
            <v-flex lg6 sm12>
              <v-text-field label="Telefone" v-model="userEdit.phoneNumber">
              </v-text-field>
            </v-flex>

            <v-flex lg6 sm12 v-if="userEdit['.key'] === undefined">
              <v-text-field label="Senha" v-model="password" type="password">
              </v-text-field>
            </v-flex>
            <v-flex lg6 sm12 v-if="userEdit['.key']">
              <v-text-field
                disabled
                label="UID"
                v-model="userEdit['.key']"
                type="text"
              >
              </v-text-field>
            </v-flex>
            <v-flex lg12 sm12>
              <v-checkbox v-model="userEdit.isAdmin">
                <template v-slot:label>
                  <div class="primary--text">
                    É Administrador? ({{ userEdit.isAdmin ? "Sim" : "Não" }})
                  </div>
                </template>
              </v-checkbox>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-3">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.native="cancelar()"
          >Cancelar</v-btn
        >
        <v-btn color="primary" @click.native="salvar()" v-if="!userEdit['.key']"
          >Salvar</v-btn
        >
        <v-btn color="primary" @click.native="atualizar()" v-else
          >Atualizar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
import firebase from "firebase/app";
import { db } from "@/db";
// Utilities
import { mapState } from "vuex";
export default {
  name: "Usuario",
  firebase: {
    franquias: db.ref("/franquias/"),
  },
  data() {
    return {
      dialog: false,
      valid: true,
      password: null,
      franquias: [],
    };
  },
  computed: {
    // ...mapState(["userEdit"]),
    userEdit: {
      get() {
        return this.$store.state.userEdit;
      },
      set(val) {
        this.$store.commit("SET_USER_EDIT", val);
      },
    },
    dialogUser: {
      get() {
        return this.$store.state.dialogUser;
      },
      set(val) {
        this.$store.commit("SET_DIALOG_USER", val);
      },
      
    },
    franquiasList() {
        let franquias = [];
        franquias = this.franquias.map(this.mapFranquias);
        return franquias;
      },
  },
  methods: {
    mapFranquias(item){
      let key = item[".key"];

      return {
        ...item,
        key: key,
      };
    },
    atualizarFirebase(user, newUser) {
      // console.log('Bearer '+this.$store.state.user.refreshToken)
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      // console.log(newUser)
      this.$http
        .post("/updateUser", newUser)
        .then((response) => {
          toastr.success(
            "Usuário: " + newUser.displayName + " atualizado com sucesso!",
            "Confabular"
          );
          this.dialogUser = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.error,
            "Confabular"
          );
        });
    },
    salvar() {
      let newUser = this.userEdit;
      toastr.info(
        "Enviando dados de: " + newUser.displayName + " para a nuvem!",
        "Confabular"
      );
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      newUser.password = this.password;
      // console.log('Bearer '+this.$store.state.user.refreshToken)
      this.$http
        .post("/createUser", newUser)
        .then((response) => {
          // console.log(response.data)
          if (response.data.error) {
            toastr.error(response.data.error, "Confabular");
          } else {
            toastr.success(
              "Usuário: " + newUser.displayName + " atualizado com sucesso!",
              "Confabular"
            );
            this.dialogUser = false;
          }
        })
        .catch((error) => {
          console.log(error);
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
      /*firebase;
        .auth()
        .createUserWithEmailAndPassword(newUser.email, this.password)
        .then((user) => {
          // Signed in
          this.sendFirebase(user.user, newUser);
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });*/
    },
    cancelar() {
      this.dialogUser = false;
    },
    atualizar() {
      toastr.info(
        "Enviando dados de: " + this.userEdit.displayName + " para a nuvem!",
        "Confabular"
      );
      this.atualizarFirebase(this.userEdit, this.userEdit);
    },
  },
  created() {
    // console.log(this.userEdit)
  },
  mounted() {
    // console.log(process.env.VUE_APP_ROOT_API)
  },
};
</script>
